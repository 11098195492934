import React from 'react';
import {
  FaArrowRight,
  FaCalendarAlt,
  FaEnvelope,
  FaFacebookF,
  FaInstagram,
  FaPhoneAlt,
  FaTwitter,
  FaYoutube,
} from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { IoLocationSharp } from "react-icons/io5";
import { MdLocalPhone } from "react-icons/md";
import { IoMdMail } from "react-icons/io";
import moment from 'moment';



const FooterOne = () => {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const year = moment().year();
  return (
    <>
      {/* ================== Footer One Start ==================*/}
      <footer className="footer-area  bg-cover">
        {/* <div className="footer-subscribe">
          <div className="container">
            <div
              className="footer-subscribe-inner bg-cover"
              style={{ backgroundImage: 'url("./assets/img/bg/6.png")' }}
            >
              <div className="row">
                <div className="col-lg-6">
                  <h2 className="mb-lg-0 mb-3">Subscribe To Our Newsletter</h2>
                </div>
                <div className="col-lg-6 align-self-center text-lg-end">
                  <input type="text" placeholder="Your e-mail address" />
                  <Link className="btn btn-black border-radius-0" to="#">
                    Submit now
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div className="container pd-top-30">
          <div className="row ">
            <div className="col-lg-4 col-md-6">
              <div className="widget widget_about">
                <div className="thumb">
                  <img src="assets/img/rcm-insights-logo.webp" alt="Footer logo" />
                  {/* <h4 style={{ color: "#fff" }}>RCMInsights</h4> */}
                </div>
                <div className="details">
                  <p>
                    We are the leading provider of Revenue Cycle Management, Workers Compensation, and Personal Injury solutions. With our Medical Virtual Assistant services and specialized expertise.
                  </p>
                  <p className='locationSvg'>
                    <MdLocalPhone /> <a href='tel:0512331786'>0512331786 </a>
                  </p>
                  <p className='locationSvg'>
                    <IoMdMail />  <a href='mailto:info@rcminsights.net'>info@rcminsights.net</a>
                  </p>
                  <p className='locationSvg'>
                    <IoLocationSharp /> I&T centre Street 22 , G8/4 Islamabad, Pakistan
                  </p>
                  {/* <p className="mt-3">
                    <FaPhoneAlt /> (+888) 123 456 765
                  </p>
                  <p className="mt-2">
                    <FaEnvelope /> (+888) 123 456 765
                  </p> */}
                  {/* <ul className="social-media">
                    <li>
                      <Link to="#">
                        <FaFacebookF />
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <FaTwitter />
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <FaInstagram />
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <FaYoutube />
                      </Link>
                    </li>
                  </ul> */}
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 offset-lg-1 offset-md-1">
              <div className="widget widget_nav_menu">
                <h4 className="widget-title">Quick links</h4>
                <ul>
                  <li>
                    <Link to="/" onClick={scrollToTop}>
                      <FaArrowRight /> Home
                    </Link>
                  </li>
                  <li>
                    <Link to="/about-us" onClick={scrollToTop}>
                      <FaArrowRight /> About Us
                    </Link>
                  </li>
                  <li>
                    <Link to="/" onClick={scrollToTop}>
                      <FaArrowRight /> Services
                    </Link>
                  </li>
                  <li>
                    <Link to="/contact-us" onClick={scrollToTop}>
                      <FaArrowRight /> Contact Us
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 offset-lg-1 offset-md-1">
              <div className="widget widget_nav_menu">
                <h4 className="widget-title">Our Service</h4>
                <ul>
                  <li>
                    <Link to="/revenue-cycle-management" onClick={scrollToTop} > <FaArrowRight /> Revenue Cycle Management</Link>
                  </li>
                  <li>
                    <Link to="/workers-compensation" onClick={scrollToTop}> <FaArrowRight /> Workers' Compensation</Link>
                  </li>
                  <li>
                    <Link to="/personal-injury" onClick={scrollToTop}> <FaArrowRight /> Personal Injury</Link>

                  </li>
                  <li>
                    <Link to="/virtual-medical-assistants" onClick={scrollToTop}> <FaArrowRight /> Medical Virtual Assistant</Link>

                  </li>

                </ul>
              </div>
            </div>

            {/* <div className="col-lg-3 col-md-6">
              <div className="widget widget-recent-post">
                <h4 className="widget-title">Contact us</h4>
                <ul>
                  <li>
                    <div className="media">
                      <div className="media-left">
                        <img src="assets/img/widget/1.png" alt="blog" />
                      </div>
                      <div className="media-body align-self-center">
                        <div className="post-info mb-2">
                          <FaCalendarAlt />
                          <span>january 11, 2023</span>
                        </div>
                        <h6 className="title mb-0">
                          <Link to="/blog-details">
                            Social Media For Promote Business.
                          </Link>
                        </h6>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="media">
                      <div className="media-left">
                        <img src="assets/img/widget/2.png" alt="blog" />
                      </div>
                      <div className="media-body align-self-center">
                        <div className="post-info mb-2">
                          <FaCalendarAlt />
                          <span>january 11, 2023</span>
                        </div>
                        <h6 className="title mb-0">
                          <Link to="/blog-details">
                            Marketing For Base market watch
                          </Link>
                        </h6>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div> */}
          </div>
        </div>
        <div className="footer-bottom">
          <div className="container">
            <div className="row">
              <div className="col-md-12 align-self-center text-center">
                <p>Copyright © {year} RCMInsights | All Rights Reserved.</p>
              </div>
              {/* <div className="col-md-6 text-lg-end">
                <Link to="#">Terms &amp; Condition</Link>
                <Link to="#">Privacy Policy</Link>
                <Link to="#">Contact Us</Link>
              </div> */}
            </div>
          </div>
        </div>
      </footer>
      {/* ================== Footer One  end ==================*/}
    </>
  );
};

export default FooterOne;
