import React from 'react'
import sideImage from './img/img-02.jpg'
import './style.css'

function NewsletterSection() {
    return (
        <>
            <section className='newsletter-section-div'>
                <div className="container-fluid">
                    <div className="row ">
                        <div className="col-md-7 first-div-column p-0 ">
                            <div className="first-div">
                                <h2>Our Take Newsletter</h2>
                                <p>Significance of efficient revenue management in the healthcare industry.</p>
                                <button className='mb-3'>Subscribe</button>
                            </div>
                        </div>
                        <div className="col-md-5 p-0">
                            <img src={sideImage} alt="Side Image" className='newsLetterInnerImage' />
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default NewsletterSection